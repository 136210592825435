<template>
  <div class="pushEffectAnalysis mt-20">
    <!-- 推送进度 -->
    <div class="push-progress">
      <div class="first-title mb-20">推送进度</div>
      <div class="progress-text">推送状态：{{ progressText }}</div>
    </div>
    <!-- 文章阅读情况 -->
    <div class="article-reading mb-20" v-show="showWZYDL">
      <div class="first-title mb-20">文章阅读量排行</div>
      <!-- table表格 -->
      <commonTable
        :table-data="articleTableData"
        :column-data="articleColumn"
        :init-obj="articlePageObj"
        @onChangePageData="
          (pageObj) => {
            getArticleListData(pageObj)
          }
        "
      />
    </div>
    <!-- 阅读文章用户分布 -->
    <div
      class="reading-article-user-distribution mb-20"
      v-if="showWZYHJG || showWZYHQK"
    >
      <div class="first-title">阅读文章用户分布</div>
      <div v-if="showWZYHJG">
        <div class="echarts-title">
          <div class="second-title">
            <span>阅读文章用户的机构分布</span>
          </div>
          <div class="top">
            <div
              :class="WZYHJGActive === 10 ? 'active' : ''"
              class="mr-8"
              @click="WZYHJGActive = 10"
              data-track="增值服务-主动传播-邮件传播-推送结果-阅读文章用户的机构分布-Top10"
            >
              Top10
            </div>
            <div
              :class="WZYHJGActive === 15 ? 'active' : ''"
              class="mr-8"
              @click="WZYHJGActive = 15"
              data-track="增值服务-主动传播-邮件传播-推送结果-阅读文章用户的机构分布-Top15"
            >
              Top15
            </div>
            <div
              :class="WZYHJGActive === 20 ? 'active' : ''"
              @click="WZYHJGActive = 20"
              data-track="增值服务-主动传播-邮件传播-推送结果-阅读文章用户的机构分布-Top20"
            >
              Top20
            </div>
          </div>
        </div>
        <!-- 阅读文章用户的机构分布echarts图 -->
        <div
          id="chartWZYHJG"
          :style="{
            width: '100%',
            height: '420px'
          }"
        />
      </div>
      <div v-if="showWZYHQK">
        <!-- 阅读文章用户的期刊分布 -->
        <div class="echarts-title mt-20">
          <div class="second-title">
            <span
              >阅读文章用户的期刊分布
              <el-tooltip
                class="item"
                effect="dark"
                content="阅读文章用户的期刊分布：截至当前时间，阅读文章的用户，曾发表过文章的期刊的分布"
                placement="top"
              >
                <i class="el-icon-question" />
              </el-tooltip>
            </span>
          </div>
          <div class="top">
            <div
              :class="WZYHQKActive === 10 ? 'active' : ''"
              class="mr-8"
              @click="WZYHQKActive = 10"
              data-track="增值服务-主动传播-邮件传播-推送结果-阅读文章用户的期刊分布-Top10"
            >
              Top10
            </div>
            <div
              :class="WZYHQKActive === 15 ? 'active' : ''"
              class="mr-8"
              @click="WZYHQKActive = 15"
              data-track="增值服务-主动传播-邮件传播-推送结果-阅读文章用户的期刊分布-Top15"
            >
              Top15
            </div>
            <div
              :class="WZYHQKActive === 20 ? 'active' : ''"
              @click="WZYHQKActive = 20"
              data-track="增值服务-主动传播-邮件传播-推送结果-阅读文章用户的期刊分布-Top20"
            >
              Top20
            </div>
          </div>
        </div>
        <!-- 阅读文章用户的期刊分布echarts图 -->
        <div
          id="chartWZYHQK"
          :style="{
            width: '100%',
            height: '420px'
          }"
        />
      </div>
    </div>
    <!-- 用户活跃度排行 -->
    <div class="mb-20 echarts-title">
      <div class="first-title mb-20">用户活跃度排行</div>
      <div class="top">
        <div
          :class="userActivity === 50 ? 'active' : ''"
          class="mr-8"
          @click="userActivity = 50"
          data-track="增值服务-主动传播-邮件传播-推送结果-用户活跃度排行-Top50"
        >
          Top50
        </div>
        <div
          :class="userActivity === 'author' ? 'active' : ''"
          @click="userActivity = 'author'"
          data-track="增值服务-主动传播-邮件传播-推送结果-用户活跃度排行-本刊作者"
        >
          本刊作者
        </div>
      </div>
      <commonTable
        :table-data="authorTableData"
        :column-data="authorColumn"
        :init-obj="authorPageObj"
        @onChangePageData="
          (pageObj) => {
            getAuthorListData(pageObj)
          }
        "
        @onChangeSort="
          (prop, order) => {
            handleChangeSort(prop, order)
          }
        "
      />
    </div>
  </div>
</template>

<script>
import { Get, ProjectConfig } from 'kjqk-commons/src/common/index.js'
import commonTable from '@comp/commonTable'
import echarts from 'echarts'
import { mapState } from 'vuex'
const api = ProjectConfig.emailPush
export default {
  name: 'PushEffectAnalysis',
  components: {
    commonTable
  },
  data() {
    return {
      taskId: '',
      articleTableData: [], // 推送前后单篇文章阅读情况
      articleColumn: [
        {
          title: '标题',
          prop: 'topic',
          special: true,
          width: '320px',
          notSortable: true,
          clickParam: 'url'
        },
        {
          title: '作者',
          prop: 'authors',
          notSortable: true
        },
        {
          title: '机构',
          prop: 'org',
          notSortable: true
        },
        {
          title: '年/卷/期',
          prop: 'pubYearStage',
          notSortable: true
        },
        {
          title: '关键词',
          prop: 'keywords',
          notSortable: true
        },
        {
          title: '阅读量（邮件）',
          prop: 'clickCount',
          notSortable: true,
          tooltip: true,
          tooltipContent:
            '阅读量（邮件）：截至当前时间，点击推送邮件中链接来阅读文章的次数'
        }
      ],
      articlePageObj: {
        page: 1,
        size: 10
      },
      WZYHJGActive: 20,
      WZYHQKActive: 20,
      authorTableData: [],
      authorColumn: [
        {
          title: '姓名',
          prop: 'name',
          notSortable: true
        },
        {
          title: '机构',
          prop: 'orgNames',
          notSortable: true
        },
        {
          title: '研究领域',
          prop: 'domain',
          notSortable: true
        },
        {
          title: '阅读文章次数',
          prop: 'sumReadCount'
        },
        {
          title: '阅读邮件次数',
          prop: 'sumOpenEmailCount',
          notSortable: false
        }
      ],
      authorPageObj: {
        page: 1,
        size: 10,
        sort: 'sumReadCount,desc'
      },
      WZYHJGdata: '',
      WZYHQKdata: '',
      currentItem: '',
      progressText: '',
      userActivity: 50
    }
  },
  computed: {
    ...mapState('incrementService', ['magId']),
    showWZYHJG() {
      return (
        this.WZYHJGdata &&
        this.WZYHJGdata.readArticleOrgList &&
        this.WZYHJGdata.readArticleOrgList.length > 0
      )
    },
    showWZYHQK() {
      return (
        this.WZYHQKdata &&
        this.WZYHQKdata.readArticleMag &&
        this.WZYHQKdata.readArticleMag.length > 0
      )
    },
    showWZYDL() {
      return this.currentItem.type !== 4
    }
  },
  watch: {
    magId(n) {
      if (!n) {
        this.initData()
      }
    },
    WZYHJGActive() {
      this.getChartData(
        'WZYHJG',
        { taskId: this.taskId, page: 0, size: this.WZYHJGActive },
        api.GetReadArticleOrg.url
      )
    },
    WZYHQKActive() {
      this.getChartData(
        'WZYHQK',
        { taskId: this.taskId, page: 0, size: this.WZYHQKActive },
        api.GetReadArticleMag.url
      )
    },
    userActivity() {
      this.getAuthorListData()
    }
  },
  mounted() {
    window.addEventListener('resize', this.adjustTable)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustTable)
  },
  methods: {
    initData() {
      this.articleTableData = []
      this.chartWZYHJG()
      this.chartWZYHQK()
    },
    // 窗口大小改变重新渲染echarts
    adjustTable() {
      if (document.getElementById('chartWZYHJG')) {
        echarts.init(document.getElementById('chartWZYHJG')).resize()
      }
      if (document.getElementById('chartWZYHQK')) {
        echarts.init(document.getElementById('chartWZYHQK')).resize()
      }
    },
    // 推送任务推送进度状态
    getPushStatus() {
      let params = {
        id: this.taskId
      }
      let url = api.GetPushStatus.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          if (data === 1) {
            if (
              !this.currentItem.receiverCount ||
              !this.currentItem.prepareReceiverCount
            ) {
              this.progressText = `推送中 正在准备推送...`
            } else if (
              this.currentItem.receiverCount >=
              this.currentItem.prepareReceiverCount
            ) {
              this.progressText = `推送中 正在等待推送完成...`
            } else {
              let progress = (
                (this.currentItem.receiverCount /
                  this.currentItem.prepareReceiverCount) *
                100
              ).toFixed(2)
              this.progressText = `推送中 已推送${progress}%`
            }
          } else if (data === 2) {
            this.progressText = `已试推送`
          } else if (data === 3) {
            this.progressText = `已推送`
          } else {
            this.progressText = `待推送`
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    getData(item) {
      this.currentItem = item
      this.taskId = item.id
      Promise.all([
        this.getPushStatus(),
        this.getArticleListData(),
        this.getAuthorListData(),
        // 阅读文章用户的机构分布
        this.getChartData(
          'WZYHJG',
          { taskId: this.taskId, page: 0, size: this.WZYHJGActive },
          api.GetReadArticleOrg.url
        ),
        // 阅读文章用户的期刊分布
        this.getChartData(
          'WZYHQK',
          { taskId: this.taskId, page: 0, size: this.WZYHQKActive },
          api.GetReadArticleMag.url
        )
      ]).then(() => {
        this.adjustTable()
        this.$emit('changeScroll')
      })
    },
    // 获取echarts数据
    getChartData(type, params, url) {
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            let _this = this
            this.$nextTick(() => {
              if (type === 'WZYHJG') {
                _this.WZYHJGdata = data
                if (_this.showWZYHJG) {
                  _this.$nextTick(() => {
                    _this.chartWZYHJG(data)
                  })
                }
              } else if (type === 'WZYHQK') {
                _this.WZYHQKdata = data
                if (_this.showWZYHQK) {
                  _this.$nextTick(() => {
                    _this.chartWZYHQK(data)
                  })
                }
              }
            })
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    // 推送前后单篇文章阅读情况table表格
    getArticleListData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        page: page - 1,
        size,
        taskId: this.taskId
      }
      let url = api.GetArticleClickList.url
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            const { content = [], totalElements = 0 } = data
            this.articlePageObj = {
              page,
              size,
              total: totalElements
            }
            this.articleTableData = content
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    // 阅读文章用户的机构分布echarts图
    chartWZYHJG(data) {
      if (!document.getElementById('chartWZYHJG')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartWZYHJG'))
      const titleArr = []
      const countArr = []
      if (data && data.readArticleOrgList.length > 0) {
        data.readArticleOrgList.map((item) => {
          titleArr.push(item.orgName)
          countArr.push(item.count)
        })
      }
      myChart.setOption(
        {
          color: ['#0070C0'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: (value) => {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '12%',
            top: '3%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              data: countArr,
              barWidth: 20
            }
          ]
        },
        true
      )
    },
    // 阅读文章用户的期刊分布echarts图
    chartWZYHQK(data) {
      if (!document.getElementById('chartWZYHQK')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartWZYHQK'))
      const titleArr = []
      const countArr = []
      if (data && data.readArticleMag.length > 0) {
        data.readArticleMag.map((item) => {
          titleArr.push(item.magName)
          countArr.push(item.count)
        })
      }
      myChart.setOption(
        {
          color: ['#0070C0'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: (value) => {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '12%',
            top: '3%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              data: countArr,
              barWidth: 20
            }
          ]
        },
        true
      )
    },
    // 用户活跃度排行
    getAuthorListData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      let params = {}
      if (this.userActivity === 50) {
        params = {
          page: page - 1,
          size,
          sort: this.authorPageObj.sort,
          taskId: this.taskId,
          top: 50
        }
      } else {
        params = {
          page: page - 1,
          size,
          sort: this.authorPageObj.sort,
          taskId: this.taskId,
          top: 9999,
          filterMag: true
        }
      }
      let url = api.GetAuthorSumList.url
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            const { content = [], totalElements = 0 } = data
            this.authorPageObj = {
              page,
              size,
              sort: this.authorPageObj.sort,
              total: totalElements
            }
            this.authorTableData = content
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    // 排序的改变了
    handleChangeSort(prop, order) {
      let sortValue = ''
      if (order && order !== '') {
        if (order === 'ascending') {
          sortValue = prop + ',asc'
        } else {
          sortValue = prop + ',desc'
        }
      }
      this.authorPageObj.sort = sortValue
      this.getAuthorListData()
    }
  }
}
</script>

<style lang="scss" scoped>
.pushEffectAnalysis {
  .push-progress {
    .progress-text {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
  .article-reading {
    .table-title {
      margin-top: 20px;
    }
    .conter_3 {
      margin: 0 !important;
    }
  }
}
</style>
