<template>
  <div class="citationTrackingDetails" v-loading="loading">
    <div class="basicInfo">
      <!-- 标题 -->
      <p
        v-if="articleDetails.title"
        class="article-title"
        v-html="articleDetails.title"
      />
      <!-- 摘要 -->
      <div v-if="articleDetails.abstractContent" class="abstractContent">
        摘要：
        <p v-html="articleDetails.abstractContent" />
      </div>
      <!-- 关键词 -->
      <div v-if="articleDetails.keywords" class="keywords">
        关键词：
        <p v-html="articleDetails.keywords" />
      </div>
      <!-- 作者 -->
      <div v-if="articleDetails.authors" class="authors">
        作者：
        <p v-html="articleDetails.authors" />
      </div>
      <!-- 刊名 -->
      <div v-if="articleDetails.magCn" class="magCn">
        刊名：
        <p v-html="articleDetails.magCn" />
      </div>
      <!-- 英文刊名 -->
      <div v-if="articleDetails.magEn" class="magEn">
        Journal：
        <p v-html="articleDetails.magEn" />
      </div>
      <!-- 年卷期 -->
      <div v-if="showYearIssue" class="year">
        年，卷，期：
        <p
          v-html="
            articleDetails.year +
            ',' +
            articleDetails.publishVol +
            ',' +
            articleDetails.magNumber
          "
        />
      </div>
      <!-- 所属期刊栏目 -->
      <div v-if="articleDetails.columnBelong" class="columnBelong">
        所属期刊栏目：
        <p v-html="articleDetails.columnBelong" />
      </div>
      <!-- 分类号 -->
      <div v-if="articleDetails.doi" class="doi">
        分类号：
        <p v-html="articleDetails.doi" />
      </div>
    </div>
    <div class="line"></div>
    <div class="articleList">
      <div
        class="articleListItem"
        v-for="(item, index) in articleTableData"
        :key="index"
      >
        <div class="flex title">
          <span>论文名称： {{ item.title }}</span>
          <a
            :href="item.officialWebsiteUrl"
            target="_blank"
            rel="noopener noreferrer"
            >阅读原文</a
          >
        </div>
        <div
          class="flex mt-10"
          style="align-items: baseline"
          v-if="item.authorList && item.authorList.length > 0"
        >
          <span>引用作者： </span>
          <div class="authorList">
            <div
              class="authorItem"
              v-for="(it, inx) in item.authorListHaveRead"
              :key="inx"
            >
              <div>
                <span>{{ it.name }}</span>
                <span style="margin-left: 10px">{{ it.org }}</span>
                <span class="read" style="margin-left: 10px"
                  ><i class="el-icon-warning"></i>该作者曾阅读过传播邮件</span
                >
              </div>
            </div>
            <div class="authorItem flex">
              <span
                v-for="(it, inx) in item.authorListNoRead"
                :key="inx"
                style="margin-right: 10px"
                >{{ it.name }}</span
              >
            </div>
          </div>
        </div>
        <div class="flex mt-10 magCn">
          <span>来源期刊：</span>
          <div>
            <div>
              《{{ item.magCn }}》
              <span v-if="Number.isInteger(item.year) && item.year > 0">
                {{ item.year }}年第{{ ~~item.magNumber }}期，
              </span>
              {{ item.pageNum }}
            </div>
            <div class="flex" style="margin-top: 10px">
              <div
                v-for="(it, inx) in JSON.parse(item.magInfo.includedDatabases)"
                :key="inx"
                class="flex tag"
                style="align-items: center"
              >
                <img
                  src="@/assets/img/icon.png"
                  alt=""
                  style="width: 14px; height: 14px; margin-right: 2px"
                />
                <li v-if="it.indexOf('JST') != -1">JST</li>
                <li v-else-if="it.indexOf('Pж(AJ)') != -1">Pж(AJ)</li>
                <li v-else-if="it.indexOf('CA') != -1">CA</li>
                <li v-else-if="it.indexOf('EI') != -1">EI</li>
                <li v-else-if="it.indexOf('CSCD') != -1">CSCD</li>
                <li v-else-if="it.indexOf('WJCI') != -1">WJCI</li>
                <li v-else-if="it.indexOf('CSSCI') != -1">CSSCI</li>
                <li v-else>北大核心</li>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        style="margin-top: 20px"
        @current-change="getTableData"
        :current-page="articlePageObj.page"
        layout="total, prev, pager, next, jumper"
        :total="articlePageObj.total"
        :pager-count="5"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { Get } from 'kjqk-commons/src/common/index.js'
export default {
  name: 'CitationTrackingDetails',
  components: {},
  props: {},
  data() {
    return {
      articleDetails: {},
      articleTableData: [],
      articlePageObj: {
        page: 1,
        size: 10
      },
      loading: false
    }
  },
  watch: {},
  computed: {
    showYearIssue() {
      return this.articleDetails.year
        ? true
        : this.articleDetails.publishVol
        ? true
        : !!this.articleDetails.magNumber
    }
  },
  mounted() {
    this.loading = true
    this.getDetails()
    this.getTableData()
  },
  methods: {
    getDetails() {
      let url = '/das-api/vas/article/getArticleInfo'
      let params = {
        id: this.$route.query.articleId
      }
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.articleDetails = data
        }
      })
    },
    getTableData(pageParams = {}) {
      let url = '/das-api/vas/citAfterRead/citArticleInfo'
      const { page = 1, size = 10 } = pageParams
      const params = {
        page: page - 1,
        size,
        articleId: this.$route.query.articleId
      }
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.loading = false
          this.articleTableData = content
          if (this.articleTableData.length > 0) {
            this.articleTableData.map((item) => {
              item.authorListHaveRead = []
              item.authorListNoRead = []
              if (item.authorList && item.authorList.length > 0) {
                item.authorList.map((it) => {
                  if (it.isRead) {
                    item.authorListHaveRead.push(it)
                  } else {
                    item.authorListNoRead.push(it)
                  }
                })
              }
            })
          }
          this.articlePageObj = {
            page,
            size,
            total: totalElements
          }
        }
      })
    }
  }
}
</script>

<style lang="scss"></style>
<style lang="scss" scoped>
.citationTrackingDetails {
  .basicInfo {
    padding: 20px;
    div {
      margin-bottom: 4px;
      line-height: 18px;
      font-weight: 600;
      font-size: 15px;
      p {
        display: inline;
        font-weight: 500;
        line-height: 24px;
        color: #333;
        font-size: 14px;
      }
    }
  }
  .article-title {
    font-size: 16px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    font-weight: 600;
    border-bottom: 1px solid #d0d0d0;
  }
  .line {
    width: 100%;
    height: 10px;
    background-color: #f0f2f5;
  }
  .articleList {
    padding: 0 20px;
    font-size: 15px;
    .articleListItem {
      padding: 20px 0;
      border-bottom: 1px solid #d0d0d0;
      .title {
        align-items: center;
        a {
          cursor: pointer;
          margin-left: 10px;
          background: #4054af;
          color: #fff;
          border-radius: 20px;
          padding: 2px 6px;
          font-size: 14px;
        }
      }
      .mt-10 {
        margin-top: 10px;
      }
      .authorList {
        .authorItem {
          line-height: 26px;
          .read {
            color: red;
            font-size: 14px;
          }
        }
      }
      .tag {
        background: rgba($color: #4054af, $alpha: 0.3);
        border-radius: 30px;
        color: #4054af;
        text-align: center;
        margin-right: 15px;
        padding: 1px 4px;
      }
      .magCn {
        img {
          width: 20px;
        }
      }
    }
    .flex {
      display: flex;
    }
  }
}
</style>
