<!-- analysisYearReport -->
<template>
  <div class="analysisYearReport">
    <div
      class="download-btn"
      @click="printOut(reportTitle)"
      :class="isDisabled ? 'download-btn-disabled' : ''"
    >
      <img v-if="isDisabled" :src="downloadReportDisabledImg" alt="" />
      <img v-else :src="downloadReportImg" alt="" />
      下载报告
    </div>
    <div class="analysisYearReport-content" id="analysisYearReportContent">
      <div class="page page1">
        <div class="header">
          <div class="title">
            {{ reportTitle || '' }}
          </div>
          <div class="icons">
            <div class="line"></div>
            <img class="small" :src="smallStar" alt="" />
            <img class="big" :src="bigStar" alt="" />
            <img class="small" :src="smallStar" alt="" />
            <div class="line"></div>
          </div>
          <div class="dates">
            <div>数据统计日期：{{ todayDate }}</div>
          </div>
        </div>
        <div class="main-content-box">
          <div class="num-count-box">
            <div
              class="num-count-item"
              v-for="(item, index) in numCountList"
              :key="index"
            >
              <div class="num-box">
                <div :class="['num' + index, 'num']">{{ item.count || 0 }}</div>
                <div class="text">{{ item.text }}</div>
              </div>
              <div class="rate-text">
                <span class="text"> {{ item.averageText }}</span>
                <span class="text"> {{ item.averageCount }}</span>
              </div>
            </div>
          </div>
          <!-- 邮件传播/微信传播 -->
          <div class="part part1 flex-content">
            <div class="emailPush">
              <div class="first-title">邮件传播</div>
              <div class="num-list">
                <div
                  class="list-item"
                  v-for="(item, index) in emailInfoList"
                  :key="index"
                >
                  <div class="num-box">
                    <div class="num">{{ item.num || '--' }}</div>
                    <div class="text">{{ item.text }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="weChatPush">
              <div class="first-title">微信传播</div>
              <div class="num-list">
                <div
                  class="list-item"
                  v-for="(item, index) in wxInfoList"
                  :key="index"
                >
                  <div class="num-box">
                    <div class="num">{{ item.num || '--' }}</div>
                    <div class="text">{{ item.text }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 本刊发文作者及白名单学者阅读统计 -->
          <div class="part part2">
            <h3>本刊发文作者及白名单学者阅读统计</h3>
            <div class="flex-content">
              <div class="emailPush">
                <div class="first-title">本刊发文作者阅读统计</div>
                <div class="num-list">
                  <div
                    class="list-item"
                    v-for="(item, index) in BKFWInfoList"
                    :key="index"
                  >
                    <div class="num-box">
                      <div class="num">{{ item.num || '--' }}</div>
                      <div class="text">{{ item.text }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="weChatPush">
                <div class="first-title">白名单学者阅读统计</div>
                <div class="num-list">
                  <div
                    class="list-item"
                    v-for="(item, index) in blackInfoList"
                    :key="index"
                  >
                    <div class="num-box">
                      <div class="num">{{ item.num || '--' }}</div>
                      <div class="text">{{ item.text }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-content mt-20">
              <div class="left-content">
                <div class="first-title mb-20">本刊作者活跃排行Top10</div>
                <commonTable
                  :table-data="BKZZHYPHTableData"
                  :column-data="BKZZHYPHColumnData"
                  serial-text="排名"
                  serial-width="60px"
                  no-pagination
                  align-text="left"
                />
              </div>
              <div class="right-content">
                <div class="first-title mb-20">白名单学者活跃排行Top10</div>
                <commonTable
                  :table-data="BMDXZHYPHTableData"
                  :column-data="BMDXZHYPHColumnData"
                  serial-text="排名"
                  no-pagination
                  align-text="left"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page page2">
        <div class="main-content-box">
          <!-- 阅读邮件用户来源 -->
          <div class="part part3">
            <h3>阅读邮件用户来源</h3>
            <div class="first-title mb-20">机构来源分布及排行（人数Top15）</div>
            <div
              id="chartJGLYFB"
              :style="{
                width: '100%',
                height: '340px'
              }"
            />
            <div class="flex-content mt-20">
              <div class="left-content">
                <div class="first-title mb-20">
                  引用刊作者来源分布及排行（人数Top10）
                </div>
                <div
                  id="chartYYKZZLYFB"
                  :style="{
                    width: '100%',
                    height: '300px'
                  }"
                />
              </div>
              <div class="right-content">
                <div class="first-title mb-20">
                  引证刊作者来源分布及排行（人数Top10）
                </div>
                <div
                  id="chartYZKZZLYFB"
                  :style="{
                    width: '100%',
                    height: '300px'
                  }"
                />
              </div>
            </div>
            <div class="flex-content mt-20">
              <div class="left-content">
                <div class="first-title mb-20">阅读邮件用户地域分布-国内</div>
                <commonTable
                  :table-data="chinaTableData"
                  :column-data="chinaColumnData"
                  no-pagination
                  align-text="left"
                />
                <p
                  class="mt-20"
                  style="font-size: 14px; text-align: center; color: #999"
                >
                  国内阅读邮件用户分布地区共{{ chinaLength }}个。
                </p>
              </div>
              <div class="right-content">
                <div class="first-title mb-20">阅读邮件用户地域分布-海外</div>
                <commonTable
                  :table-data="worldTableData"
                  :column-data="worldColumnData"
                  no-pagination
                  align-text="left"
                />
                <p
                  class="mt-20"
                  style="font-size: 14px; text-align: center; color: #999"
                >
                  海外阅读邮件用户分布地区共{{ worldLength }}个。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page page3">
        <div class="main-content-box">
          <!-- 年度被引 -->
          <div class="part part4">
            <h3>年度被引</h3>
            <div class="part4-content">
              <div class="top">
                <div class="content1">
                  <img class="mag-img" :src="img1" alt="" />
                </div>
                <img class="arrow-right" :src="arrowRight" alt="" />
                <div class="content2">
                  <div
                    id="chartYZWX1"
                    :style="{
                      width: '115px',
                      height: '115px',
                      margin: 'auto'
                    }"
                  />
                </div>
                <img class="arrow-right" :src="arrowRight" alt="" />
                <div class="content3">
                  <div
                    id="chartYZWX2"
                    :style="{
                      width: '115px',
                      height: '115px',
                      margin: 'auto'
                    }"
                  />
                </div>
              </div>
              <div class="bottom mt-20">
                <div class="numBox numBox1">
                  <p>
                    本年度累计发表文献<span>{{
                      yearCitationInfo.magArticleCount || '--'
                    }}</span
                    >篇
                  </p>
                </div>
                <div class="numBox numBox2">
                  <p>
                    其中<span>{{ yearCitationInfo.count }}</span
                    >篇被<span>{{
                      yearCitationInfo.citationsCount || '--'
                    }}</span
                    >篇文献引用
                  </p>
                </div>
                <div class="numBox numBox3">
                  <p
                    v-for="(
                      item, index
                    ) in yearCitationInfo.citationsArticleCountGroupByType"
                    :key="index"
                  >
                    <span
                      class="dot"
                      :style="{ background: colorArr[index] }"
                    ></span
                    >{{ item.type }}：<span>{{ item.count }}</span
                    >篇
                  </p>
                </div>
              </div>
            </div>
            <div class="first-title mb-20 mt-20">本年度文献被引排行Top10</div>
            <commonTable
              :table-data="BNDWXBYPHTableData"
              :column-data="BNDWXBYPHColumnData"
              serial-text="排名"
              no-pagination
              align-text="left"
            />
            <div class="flex-content">
              <div class="left-content">
                <div class="first-title mb-20 mt-20">
                  本年度发文机构被引统计Top10
                </div>
                <commonTable
                  :table-data="BNDFWJGBYTableData"
                  :column-data="BNDFWJGBYColumnData"
                  serial-text="排名"
                  no-pagination
                  align-text="left"
                />
              </div>
              <div class="right-content">
                <div class="first-title mb-20 mt-20">
                  本年度引证期刊引证统计Top10
                </div>
                <commonTable
                  :table-data="BNDYZQKYZTableData"
                  :column-data="BNDYZQKYZColumnData"
                  serial-text="排名"
                  no-pagination
                  align-text="left"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page page4">
        <div class="main-content-box">
          <!-- 热门内容 -->
          <div class="part part5">
            <h3>热门内容</h3>
            <div class="first-title mb-20">本年度文献关键词分布</div>
            <div class="flex-content">
              <div
                id="chartGJC"
                :style="{
                  width: '55%',
                  height: '420px'
                }"
              />
              <commonTable
                class="GJCTable"
                no-pagination
                align-text="left"
                serial-text="排名"
                :table-data="GJCTableData"
                :column-data="GJCColumnData"
              />
            </div>
            <div class="first-title mb-20 mt-20">
              本年度文献阅读量（引流）排行Top10
            </div>
            <commonTable
              class="BNDWXYDLYLTable"
              no-pagination
              align-text="left"
              serial-text="排名"
              :table-data="BNDWXYDLYLTableData"
              :column-data="BNDWXYDLYLColumnData"
            />
          </div>
        </div>
        <p class="tip">北京北大方正电子有限公司提供技术支持</p>
      </div>
    </div>
  </div>
</template>
<script>
import { Get, ProjectConfig } from 'kjqk-commons/src/common/index.js'
import { mapState } from 'vuex'
import echarts from 'echarts'
import commonTable from '@comp/commonTable'
import 'echarts-wordcloud'
import { getPdf } from '@assets/js/htmlToPdf.js'
const CEApi = ProjectConfig.communicationEffectStatistics
export default {
  name: 'AnalysisYearReport',
  data() {
    return {
      loading: false,
      isDisabled: true,
      downloadReportImg: require('@assets/img/download-report.png'),
      downloadReportDisabledImg: require('@assets/img/download-report-disabled.png'),
      smallStar: require('@assets/img/star-small.png'),
      bigStar: require('@assets/img/star-big.png'),
      arrowRight: require('@assets/img/arrow-right.png'),
      img1: require('@assets/img/img1.png'),
      basicInfo: {},
      pushTaskInfo: {},
      emailInfoList: [],
      wxInfoList: [],
      BKFWInfoList: [],
      blackInfoList: [],
      numCountList: [],
      yearCitationInfo: {},
      colorArr: [
        '#5470C6',
        '#EE6666',
        '#91CC75',
        '#FAC858',
        '#37CBCB',
        '#90C340',
        '#F6B1B4',
        '#FC8452'
      ],
      BKZZHYPHTableData: [],
      BKZZHYPHColumnData: [
        {
          title: '姓名',
          prop: 'name',
          notSortable: true,
          width: '80px',
          align: 'center'
        },
        {
          title: '用户机构',
          prop: 'org',
          notSortable: true
        },
        {
          title: '阅读文章次数',
          prop: 'readCount',
          notSortable: true,
          width: '100px',
          align: 'center'
        }
      ],
      BMDXZHYPHTableData: [],
      BMDXZHYPHColumnData: [
        {
          title: '姓名',
          prop: 'name',
          notSortable: true
        },
        {
          title: '阅读文章次数',
          prop: 'readCount',
          notSortable: true,
          width: '100px',
          align: 'center'
        }
      ],
      BNDWXBYPHTableData: [],
      BNDWXBYPHColumnData: [
        {
          title: '刊期',
          prop: 'yearIssueText',
          notSortable: true,
          width: '100px'
        },
        {
          title: '被引文献',
          prop: 'root_title',
          notSortable: true
        },
        {
          title: '被引次数',
          prop: 'frequency',
          notSortable: true,
          width: '100px',
          align: 'center'
        }
      ],
      BNDFWJGBYTableData: [],
      BNDFWJGBYColumnData: [
        {
          title: '机构',
          prop: 'INSTITUTION',
          notSortable: true
        },
        {
          title: '被引次数',
          prop: 'frequency',
          notSortable: true,
          width: '100px',
          align: 'center'
        }
      ],
      BNDYZQKYZTableData: [],
      BNDYZQKYZColumnData: [
        {
          title: '期刊',
          prop: 'CITATIONS_MAG',
          notSortable: true
        },
        {
          title: '引证次数',
          prop: 'frequency',
          notSortable: true,
          width: '100px',
          align: 'center'
        }
      ],
      GJCTableData: [],
      GJCColumnData: [
        {
          title: '关键词',
          prop: 'keywords',
          notSortable: true
        },
        {
          title: '频次',
          prop: 'count',
          notSortable: true,
          width: '50px',
          align: 'center'
        }
      ],
      BNDWXYDLYLTableData: [],
      BNDWXYDLYLColumnData: [
        {
          title: '文献标题',
          prop: 'title',
          notSortable: true
        },
        {
          title: '作者',
          prop: 'authors',
          notSortable: true,
          width: '220px'
        },
        {
          title: '关键词',
          prop: 'keywords',
          notSortable: true,
          width: '220px'
        },
        {
          title: '阅读量（引流）',
          prop: 'wechatAndEmailCount',
          notSortable: true,
          width: '140px',
          align: 'center'
        }
      ],
      chinaTableData: [],
      chinaColumnData: [
        {
          title: '地区',
          prop: 'territory',
          notSortable: true
        },
        {
          title: '人数',
          prop: 'count',
          notSortable: true,
          width: '80px',
          align: 'center'
        },
        {
          title: '占比',
          prop: 'proportion',
          notSortable: true,
          width: '80px',
          align: 'center'
        }
      ],
      worldTableData: [],
      worldColumnData: [
        {
          title: '地区',
          prop: 'territory',
          notSortable: true
        },
        {
          title: '人数',
          prop: 'count',
          notSortable: true,
          width: '80px',
          align: 'center'
        },
        {
          title: '占比',
          prop: 'proportion',
          notSortable: true,
          width: '80px',
          align: 'center'
        }
      ],
      chinaLength: 0,
      worldLength: 0
    }
  },
  props: {
    year: {
      type: [String, Number],
      default: 2024
    }
  },
  components: { commonTable },
  computed: {
    ...mapState('incrementService', ['magId']),
    todayDate() {
      var date = new Date()
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      return year + '年' + month + '月' + day + '日'
    },
    reportTitle() {
      return (
        (this.basicInfo.magName ? '《' + this.basicInfo.magName + '》' : '') +
        this.year +
        '年文献主动传播数据统计报告'
      )
    }
  },
  mounted() {
    if (this.magId) {
      this.getData()
    }
  },
  watch: {
    magId() {
      this.getData()
    },
    year() {
      this.getData()
    }
  },
  methods: {
    getData() {
      // 一进来不加载蒙版
      this.loading = false
      this.$emit('loadingChange', false)
      let params = {
        magId: this.magId,
        year: this.year
      }
      Promise.allSettled([
        // 基本信息
        this.getAnnualReportInfo(),
        // 本刊作者活跃排行Top10
        this.getTableData('BKZZHYPH', CEApi.GetOfficialAuthorRead.url, params),
        // 白名单学者活跃排行Top10
        this.getTableData('BMDXZHYPH', CEApi.GetWhiteAuthorRead.url, params),
        // 机构来源分布及排行（人数Top15）
        this.getChartData(
          'JGLYFB',
          { ...params, page: 0, size: 15 },
          CEApi.GetOpenEmailOrg.url
        ),
        // 引用刊作者来源分布及排行（人数Top10）
        this.getChartData(
          'YYKZZLYFB',
          {
            magId: this.magId,
            year: this.year,
            page: 0,
            size: 10,
            sort: 'count2,desc'
          },
          CEApi.GetReferenceMag.url
        ),
        // 引证刊作者来源分布及排行（人数Top10）
        this.getChartData(
          'YZKZZLYFB',
          {
            magId: this.magId,
            year: this.year,
            page: 0,
            size: 10,
            sort: 'count2,desc'
          },
          CEApi.GetCitationMag.url
        ),
        // 年度被引
        this.getSumIssueArticleCitationInfo(),
        // 本年度文献被引排行Top10
        this.getTableData('BNDWXBYPH', CEApi.GetCountRootArticleCitations.url, {
          magId: this.magId,
          page: 0,
          size: 10,
          startTime: this.year + '-01',
          endTime: this.year + '-12'
        }),
        // 本年度发文机构被引统计Top10
        this.getTableData('BNDFWJGBY', CEApi.GetCountRootMagOrg.url, {
          magId: this.magId,
          page: 0,
          size: 10,
          startTime: this.year + '-01',
          endTime: this.year + '-12'
        }),
        // 本年度引证期刊引证统计Top10
        this.getTableData('BNDYZQKYZ', CEApi.GetCountMagCitations.url, {
          magId: this.magId,
          page: 0,
          size: 10,
          startTime: this.year + '-01',
          endTime: this.year + '-12'
        }),
        // 年度关键词
        this.getTableData('GJC', CEApi.GetYearKeywordsStatistic.url, {
          magId: this.magId,
          year: this.year
        }),
        // 年度阅读量排行榜(引流)
        this.getTableData('BNDWXYDLYL', CEApi.GetArticleClickRankListYL.url, {
          magId: this.magId,
          page: 0,
          size: 10,
          year: this.year
        }),
        // 年度地域分布-国内
        this.getTableData('diffusePathChina', CEApi.GetYearDiffusePath.url, {
          magId: this.magId,
          page: 0,
          size: 10,
          year: this.year,
          type: 1
        }),
        // 年度地域分布-海外
        this.getTableData('diffusePathWorld', CEApi.GetYearDiffusePath.url, {
          magId: this.magId,
          page: 0,
          size: 10,
          year: this.year,
          type: 2
        })
      ]).then(() => {
        console.log('全部加载完成!!!!!!!!!!!')

        /* this.loading = false */
        this.$emit('loadingChange', false)
        this.isDisabled = false
      })
    },
    // 获取echarts数据
    getChartData(type, params, url) {
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            let _this = this
            this.$nextTick(() => {
              if (type === 'JGLYFB') {
                _this.chartJGLYFB(data)
              } else if (type === 'YYKZZLYFB') {
                if (data && data.content && data.content.length > 0) {
                  _this.chartYYKZZLYFB(data.content)
                }
              } else if (type === 'YZKZZLYFB') {
                if (data && data.content && data.content.length > 0) {
                  _this.chartYZKZZLYFB(data.content)
                }
              }
              resolve()
            })
          } else {
            reject()
          }
        })
      })
    },
    getAnnualReportInfo() {
      this.numCountList = []
      this.emailInfoList = []
      this.wxInfoList = []
      this.BKFWInfoList = []
      this.blackInfoList = []
      const params = {
        magId: this.magId,
        year: this.year
      }
      let url = CEApi.GetAnnualReportInfo.url
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.basicInfo = data
            this.numCountList.push(
              {
                text: '累计文献阅读人次',
                count: data.pageView,
                averageText: '单期最高阅读人次',
                averageCount:
                  (data.maxPageViewIssue
                    ? '第' + ~~data.maxPageViewIssue + '期'
                    : '') + (data.maxPageView ? data.maxPageView + '次' : '')
              },
              {
                text: '累计文献阅读人数',
                count: data.uniqueVisitor,
                averageText: '单期最高阅读人数',
                averageCount:
                  (data.maxUniqueVisitorIssue
                    ? '第' + ~~data.maxUniqueVisitorIssue + '期'
                    : '') +
                  (data.maxUniqueVisitor ? data.maxUniqueVisitor + '人' : '')
              },
              {
                text: '累计邮件传播期数',
                count: data.countEmailTask,
                averageText: '年度出刊期数',
                averageCount: data.countAllIssue
              },
              {
                text: '累计微信传播期数',
                count: data.countNetworkTask,
                averageText: '年度出刊期数',
                averageCount: data.countAllIssue
              }
            )
            this.emailInfoList.push(
              {
                text: '邮件阅读人次',
                num: data.emailOpenCount
              },
              {
                text: '邮件阅读人数',
                num: data.emailOnlyOpenCount
              },
              {
                text: '文献阅读人次',
                num: data.emailReadCount
              },
              {
                text: '文献阅读人数',
                num: data.emailOnlyReadCount
              }
            )
            this.wxInfoList.push(
              {
                text: '微网刊访问人次',
                num: data.networkCount
              },
              {
                text: '文献阅读人次',
                num: data.networkReadCount
              },
              {
                text: '文献阅读人数 (已授权)',
                num: data.networkUserCount
              }
            )
            this.BKFWInfoList.push(
              {
                text: '邮件阅读人次',
                num: data.officialOpenEmailCount
              },
              {
                text: '邮件阅读人数',
                num: data.officialOnlyOpenEmail
              },
              {
                text: '文献阅读人次',
                num: data.officialReadCount
              },
              {
                text: '文献阅读人数',
                num: data.officialOnlyRead
              }
            )
            this.blackInfoList.push(
              {
                text: '邮件阅读人次',
                num: data.whiteListOpenEmailCount
              },
              {
                text: '邮件阅读人数',
                num: data.whiteListOnlyOpenEmail
              },
              {
                text: '文献阅读人次',
                num: data.whiteListReadCount
              },
              {
                text: '文献阅读人数',
                num: data.whiteListOnlyRead
              }
            )
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    getTableData(type, url, params) {
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            if (type === 'BKZZHYPH') {
              if (data && data.content && data.content.length > 0) {
                this.BKZZHYPHTableData = data.content
              }
            } else if (type === 'BMDXZHYPH') {
              if (data && data.content && data.content.length > 0) {
                this.BMDXZHYPHTableData = data.content
              }
            } else if (type === 'BNDWXBYPH') {
              if (data && data.content && data.content.length > 0) {
                this.BNDWXBYPHTableData = data.content
                if (this.BNDWXBYPHTableData.length > 0) {
                  this.BNDWXBYPHTableData.map((item) => {
                    item.yearIssueText =
                      (item.year ? item.year + '年' : '') +
                      (item.magNumber ? '第' + ~~item.magNumber + '期' : '')
                  })
                }
              }
            } else if (type === 'BNDFWJGBY') {
              if (data && data.content && data.content.length > 0) {
                this.BNDFWJGBYTableData = data.content
              }
            } else if (type === 'BNDYZQKYZ') {
              if (data && data.content && data.content.length > 0) {
                this.BNDYZQKYZTableData = data.content
              }
            } else if (type === 'GJC') {
              this.GJCTableData = data
              this.chartGJC(data)
            } else if (type === 'BNDWXYDLYL') {
              if (data && data.content && data.content.length > 0) {
                this.BNDWXYDLYLTableData = data.content
              }
            } else if (type === 'diffusePathChina') {
              if (data && data.content && data.content.length > 0) {
                this.chinaLength = data.totalElements
                this.chinaTableData = data.content
                this.chinaTableData.map((it) => {
                  if (
                    it &&
                    it.territory &&
                    it.territory.indexOf('中国-') != -1
                  ) {
                    it.territory = it.territory.split('中国-')[1]
                  }
                })
              }
            } else if (type === 'diffusePathWorld') {
              if (data && data.content && data.content.length > 0) {
                this.worldLength = data.totalElements
                this.worldTableData = data.content
                this.worldTableData.map((it) => {
                  if (it && it.territory && it.territory.indexOf('--') != -1) {
                    it.territory = it.territory.split('--')[0]
                  }
                })
              }
            }
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    chartJGLYFB(data) {
      if (!document.getElementById('chartJGLYFB')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartJGLYFB'))
      const titleArr = []
      const countArr = []
      if (data && data.length > 0) {
        data.map((item) => {
          titleArr.push(item.orgName)
          countArr.push(item.count || 0)
        })
      }
      myChart.setOption(
        {
          color: ['#5470C6', '#91CC75'],
          backgroundColor: '#F5F7FD',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: (value) => {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '12%',
            bottom: '18%',
            top: '8%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1
            }
          ],
          series: [
            {
              name: '',
              type: 'bar',
              data: countArr,
              barWidth: 30,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    chartYYKZZLYFB(data) {
      if (!document.getElementById('chartYYKZZLYFB')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartYYKZZLYFB'))
      const titleArr = []
      const countArr = []
      if (data && data.length > 0) {
        data.map((item) => {
          titleArr.push(item.refMagName)
          countArr.push(item.countAuthor || 0)
        })
      }
      myChart.setOption(
        {
          color: ['#5470C6', '#91CC75'],
          backgroundColor: '#F5F7FD',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: (value) => {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '12%',
            bottom: '18%',
            top: '8%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1
            }
          ],
          series: [
            {
              name: '',
              type: 'bar',
              data: countArr,
              barWidth: 30,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    chartYZKZZLYFB(data) {
      if (!document.getElementById('chartYZKZZLYFB')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartYZKZZLYFB'))
      const titleArr = []
      const countArr = []
      if (data && data.length > 0) {
        data.map((item) => {
          titleArr.push(item.ctiMagName)
          countArr.push(item.countAuthor || 0)
        })
      }
      myChart.setOption(
        {
          color: ['#5470C6', '#91CC75'],
          backgroundColor: '#F5F7FD',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: (value) => {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '12%',
            bottom: '18%',
            top: '8%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1
            }
          ],
          series: [
            {
              name: '',
              type: 'bar',
              data: countArr,
              barWidth: 30,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    getSumIssueArticleCitationInfo() {
      const params = {
        magId: this.magId,
        startTime: this.year + '-01',
        endTime: this.year + '-12',
        size: 10
      }
      let url = CEApi.GetSumIssueArticleCitation.url
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.yearCitationInfo = data
            this.chartYZWX1()
            this.chartYZWX2()
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    chartYZWX1() {
      if (!document.getElementById('chartYZWX1')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartYZWX1'))
      myChart.setOption(
        {
          color: ['#73C0DE ', '#E5E8F0'],
          title: {
            text: this.yearCitationInfo.citationsCount + '篇',
            left: 'center',
            top: '50%',
            textStyle: {
              color: '#4054AF',
              fontSize: 16,
              align: 'center',
              fontWeight: 500
            }
          },
          graphic: {
            type: 'text',
            left: 'center',
            top: '40%',
            style: {
              text: '引证文献',
              textAlign: 'center',
              fill: '#999999',
              fontSize: 14,
              fontWeight: 500
            }
          },
          series: [
            {
              name: '引证文献',
              type: 'pie',
              radius: ['100%', '70%'],
              label: {
                show: false,
                position: 'left'
              },
              hoverAnimation: false,
              data: [
                {
                  value: this.yearCitationInfo.citationsCount
                }
              ]
            }
          ]
        },
        true
      )
    },
    chartYZWX2() {
      if (!document.getElementById('chartYZWX2')) {
        return
      }
      var dataArr = this.yearCitationInfo.citationsArticleCountGroupByType
      if (dataArr && dataArr.length > 0) {
        dataArr.map((item) => {
          item.name = item.type
          item.value = item.count
        })
      }
      let _this = this
      const myChart = echarts.init(document.getElementById('chartYZWX2'))
      myChart.setOption(
        {
          color: _this.colorArr,
          title: {
            text: _this.yearCitationInfo.citationsCount + '篇',
            left: 'center',
            top: '50%',
            textStyle: {
              color: '#4054AF',
              fontSize: 16,
              align: 'center',
              fontWeight: 500
            }
          },
          graphic: {
            type: 'text',
            left: 'center',
            top: '40%',
            style: {
              text: '引证文献',
              textAlign: 'center',
              fill: '#999999',
              fontSize: 14,
              fontWeight: 500
            }
          },
          series: [
            {
              name: '引证文献',
              type: 'pie',
              radius: ['100%', '70%'],
              label: {
                show: false,
                position: 'left'
              },
              hoverAnimation: false,
              data: dataArr
            }
          ]
        },
        true
      )
    },
    chartGJC(data) {
      if (!document.getElementById('chartGJC')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartGJC'))
      let list = []
      if (data && data.length > 0) {
        list = data
        list.map((item) => {
          item.name = item.keywords
          item.value = item.count
        })
      }
      myChart.setOption(
        {
          //数据可以点击
          tooltip: {},
          series: [
            {
              // maskImage: maskResource,
              //词的类型
              type: 'wordCloud',
              //设置字符大小范围
              sizeRange: [16, 70],
              size: ['100%', '100%'],
              rotationRange: [-90, 90],
              width: '95%',
              height: '95%',
              gridSize: 4,
              textStyle: {
                normal: {
                  //生成随机的字体颜色
                  color: () => {
                    return (
                      'rgb(' +
                      [
                        Math.round(Math.random() * 160),
                        Math.round(Math.random() * 160),
                        Math.round(Math.random() * 160)
                      ].join(',') +
                      ')'
                    )
                  }
                }
              },
              //不要忘记调用数据
              data: list
            }
          ]
        },
        true
      )
    },
    printOut(name) {
      if (this.isDisabled) {
        return
      }
      this.$nextTick(() => {
        let pdfWidth =
          document.getElementsByClassName('analysisYearReport-content')[0]
            .offsetWidth / 2
        let pdfHeight =
          document.getElementsByClassName('analysisYearReport-content')[0]
            .offsetHeight / 2
        getPdf('analysisYearReportContent', name, pdfWidth, pdfHeight, 'a4')
      })
    }
  }
}
</script>
<style lang="scss">
.analysisYearReport {
  .el-table--border td,
  .el-table--border th {
    border-right: 0 !important;
  }
  .el-table--border {
    border-left: none;
  }
  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background: #fafafa;
  }
  .el-table__header-wrapper {
    .el-table__header {
      tr th {
        background: #f1f2f4;
      }
    }
  }
}
.GJCTable {
  width: 35%;
  .serial1,
  .serial2,
  .serial3 {
    width: 20px;
    height: 20px;
    background: #a6aabb;
    border-radius: 10px;
    display: inline-block;
    color: #fff;
    text-align: center;
    line-height: 20px;
  }
}
.downloadYearReport {
  .el-loading-spinner {
    top: 300px !important;
  }
}
</style>
<style lang="scss" scoped>
.analysisYearReport {
  .download-btn {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: #4054af;
    display: flex;
    line-height: 27px;
    justify-content: flex-end;
    margin: 20px;
    position: absolute;
    right: 0;
    top: 0;
    img {
      width: 30px;
      height: 30px;
      margin-right: 6px;
    }
  }
  .download-btn-disabled {
    color: #c0c4cc;
    cursor: no-drop;
  }
  .analysisYearReport-content {
    width: 965.99px;
    margin: auto;
    background-color: #f5f7fd;
    border: 1px solid #f5f7fd;
    .header {
      padding-top: 80px;
      padding-bottom: 20px;
      .title {
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #4054af;
        line-height: 36px;
        text-align: center;
      }
      .icons {
        display: flex;
        align-items: center;
        margin-top: 33px;
        .line {
          width: 46%;
          height: 2px;
          border-top: 1px solid #666666;
          border-bottom: 1px solid #666666;
        }
        .small {
          margin: 0 15px;
        }
      }
      .dates {
        text-align: right;
        margin-top: 55px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
        margin-right: 10px;
      }
    }
    .main-content-box {
      background-color: #fff;
      padding: 20px;
      .num-count-box {
        display: flex;
        justify-content: space-between;
        .num-count-item {
          width: 234px;
          height: 140px;
          background: #f5f7fd;
          text-align: center;
          .num-box {
            height: 106px;
            display: table-cell;
            vertical-align: middle;
            width: 234px;
          }
          .num {
            font-size: 32px;
            font-family: Arial;
            font-weight: 400;
          }
          .num0 {
            color: #ec5463;
          }
          .num1 {
            color: #0ba9e1;
          }
          .num2 {
            color: #2db897;
          }
          .num3 {
            color: #eea806;
          }
          .text {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-top: 10px;
          }
          .rate-text {
            border-top: 1px solid rgba(166, 170, 187, 0.25);
            height: 33px;
            line-height: 33px;
            .rate {
              color: #a6aabb;
            }
          }
        }
      }
      .flex-content {
        display: flex;
        justify-content: space-between;
        .left-content,
        .right-content {
          width: 49%;
        }
      }
      .first-title {
        color: #333333;
        font-size: 18px;
        text-indent: 10px;
        line-height: 18px;
        font-weight: normal;
        &::before {
          width: 4px;
          height: 18px;
          color: #4054af;
        }
      }
      .part {
        h3 {
          margin-top: 0;
          font-size: 22px;
        }
      }
      .part1,
      .part2 {
        margin-top: 40px;
        justify-content: space-between;
        .emailPush {
          .list-item,
          .num-box {
            width: 115px;
          }
        }
        .weChatPush {
          .list-item,
          .num-box {
            width: 155px;
          }
        }
        .emailPush,
        .weChatPush {
          width: 49%;
          .num-list {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            .list-item {
              height: 82px;
              background: #fafafa;
              text-align: center;
              .num-box {
                display: table-cell;
                vertical-align: middle;
                height: 76px;
                .num {
                  color: #4a4a4a;
                  font-size: 26px;
                  font-family: Arial;
                }
                .text {
                  color: #999999;
                  margin-top: 4px;
                  font-size: 14px;
                }
              }
              .rate-text {
                border-top: 1px solid rgba(166, 170, 187, 0.25);
                height: 30px;
                line-height: 30px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                img {
                  margin: 0 4px 3px 0;
                }
              }
            }
          }
        }
      }
      .part2 {
        margin-top: 50px;
        .left-content,
        .right-content {
          width: 49%;
        }
      }
      .part4 {
        .part4-content {
          margin-top: 30px;
          background: #f5f7fd;
          padding: 30px;
          .top {
            display: flex;
            align-items: center;
          }
          .mag-img {
            width: 115px;
            height: 76px;
          }
          .arrow-right {
            width: 54px;
            height: 19px;
            margin: 0 20px;
          }
          .content1,
          .content2 {
            width: 30%;
            text-align: center;
          }
          .content3 {
            width: 35%;
            text-align: center;
          }
        }
        .bottom {
          display: flex;
          .numBox {
            text-align: center;
            color: #666;
            font-size: 14px;
            width: 44%;
            .dot {
              width: 8px;
              height: 8px;
              display: inline-block;
              border-radius: 50%;
              margin-right: 4px;
            }
            span {
              color: #4054af;
            }
          }
          .numBox1 {
            p {
              margin-left: -30px;
            }
          }
          .numBox2 {
            width: 50%;
          }
          .numBox3 {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            p {
              width: 40%;
              text-align: left;
            }
          }
        }
      }
      .part5 {
        .flex-content {
          background: #f5f7fd;
          padding: 20px;
        }
      }
    }
  }
  .tip {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #a6aabb;
    line-height: 20px;
    text-align: center;
    margin-top: 40px;
  }
  .page {
    height: 1333.94px;
    padding: 20px;
  }
  .page2,
  .page3,
  .page4 {
    .main-content-box {
      padding: 20px 40px;
    }
  }
}
</style>
