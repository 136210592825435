<!-- downloadYearReport -->
<template>
  <div class="downloadYearReport" v-loading="loading">
    <div class="choose-year">
      <div>选择年份：</div>
      <el-select v-model="currentYear" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <analysisYearReport
      :year="currentYear"
      @loadingChange="loadingChange"
    ></analysisYearReport>
  </div>
</template>

<script>
import analysisYearReport from '@comp/report/analysisYearReport'
export default {
  name: 'DownloadYearReport',
  data() {
    return {
      options: [],
      currentYear: 2024,
      loading: true
    }
  },
  props: {},
  components: { analysisYearReport },
  computed: {},
  mounted() {
    // 今天的标准时间
    let today = new Date()
    // 本年最后标准时间
    let endYear = new Date(today.getFullYear(), 11, 31, 23, 59, 59)
    // 一天的毫秒数
    let msPerDay = 24 * 60 * 60 * 1000
    // 计算剩余毫秒除以一天的毫秒数，就是天数
    let remainingDays = Math.round(
      (endYear.getTime() - today.getTime()) / msPerDay
    )
    var year = today.getFullYear()
    if (remainingDays < 11) {
      //console.log('今天是一年中的最后10天')
      let arr = []
      for (var i = year; i > 2020; i--) {
        let item = {
          value: i,
          label: i + '年'
        }
        arr.push(item)
      }
      this.options = arr
    } else {
      //console.log('今天不是一年中的最后10天')
      let arr = []
      // 紧急修改，放开当前年份2024年
      for (var j = year; j > 2020; j--) {
        let item = {
          value: j,
          label: j + '年'
        }
        arr.push(item)
      }
      this.options = arr
    }
  },
  methods: {
    loadingChange(status) {
      this.loading = status
    }
  }
}
</script>

<style lang="scss" scoped>
.downloadYearReport {
  padding: 20px;
  position: relative;
  width: 1070px;
  margin: auto;
  .choose-year {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
}
</style>
